<template>
    <div>
        <form v-on:submit.prevent="resetPassword">
            <div class="text-2xl uppercase mb-4">
                Passwort zurücksetzen
            </div>

            <div class="p-field mb-4">
                <label class="block text-600 mb-2">
                    E-Mail-Addresse
                </label>
                <InputText
                    id="email"
                    class="w-full"
                    type="email"
                    v-model="email"
                    required
                />
                <small class="p-invalid block mt-2" v-if="message">
                    {{ message }}
                </small>
            </div>

            <Button
                type="submit"
                label="Passwort zurücksetzen"
                class="w-full"
            />
        </form>
    </div>
</template>

<script>
    import InputText from 'primevue/inputtext';
    import Button from 'primevue/button';

    export default {
        components: {
            Button,
            InputText,
        },
        data() {
            return {
                email: '',
                message: null,
            }
        },
        methods: {
            resetPassword() {
                this.$store.commit('incrementLoadingIndex');
                this.message = null;
                this.message = 'Test';
                this.$http.post(
                    'actions/password-forget',
                    {
                        email: this.email
                    }
                ).then(() => {
                    this.$store.commit('decrementLoadingIndex');
                    this.$toast.add({
                        severity: 'success',
                        summary: 'Erfolgreich',
                        detail: 'Das Passwort wurde erfolgreich zurückgesetzt. Sie erhalten in Kürze eine E-Mail.',
                        life: 3000
                    });
                    this.$router.push('/login');
                }, () => {
                    this.message = 'Leider kein Benutzer mit diesen Daten gefunden oder es wurde zu häufig ein neues Passwort angefordert.';
                    console.log(this.message);
                    this.$store.commit('decrementLoadingIndex');
                });
            }
        }
    }
</script>
